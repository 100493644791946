<template>
  <div>
    <el-form :inline="true" :model="queryForm" size="small">
      <el-form-item label="事件时间" prop="dateRange">
        <el-date-picker v-model="dateRange" @change="handleRecordDateChange" type="daterange" range-separator="至"
          start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button @click="handleSearch" type="primary">查询</el-button>
      </el-form-item>
    </el-form>
    <div class="row">
      <div id="drivers"></div>
      <div id="members"></div>
      <div id="targets"></div>
    </div>
  </div>
</template>


<script>
  import {
    getStatisticsAPI
  } from '@/api/dispatch-vehicle.js'
  import {
    formatDate
  } from '@/utils/common-fun.js'

  import * as echarts from 'echarts';


  const BaseOption = {
    title: {
      text: '',
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: [{
      type: 'category',
      data: [],
      axisTick: {
        alignWithLabel: true
      }
    }],
    yAxis: [{
      type: 'value'
    }],
    series: [{
      name: 'Direct',
      type: 'bar',
      barWidth: '60%',
      data: []
    }]
  };

  export default {
    name: 'statistics',
    data() {
      return {
        queryForm: {
          start: '',
          end: '',
        },
        // 搜索时间区间
        dateRange: [],
      }
    },
    created() {
      // 设置默认时间，区间为30天，30天前-现在
      this.queryForm.end = formatDate(new Date()).split(' ')[0];
      this.queryForm.start = formatDate(new Date(+new Date() - 2592000000)).split(' ')[0];
      this.dateRange = [this.queryForm.start, this.queryForm.end]
      this.getData();
    },
    methods: {
      // 获取派车统计信息
      getData() {
        getStatisticsAPI(this.queryForm).then(res => {
          this.setEchart(res.drivers, 'drivers');
          this.setEchart(res.members, 'members');
          this.setEchart(res.targets, 'targets');
        })
      },
      // 查询
      handleSearch() {
        this.getData();
      },
      // 设置echart图表
      setEchart(data, dom) {
        let chartDom = document.getElementById(dom);

        let myChart = echarts.init(chartDom);

        let option = JSON.parse(JSON.stringify(BaseOption));
        option.title.text = dom == 'drivers' ? '驾驶员统计' : dom == 'members' ? '用户统计' : '目的地统计';
        data.forEach(item => {
          option.xAxis[0].data.push(item.name);
          option.series[0].data.push(item.count);
        })

        option && myChart.setOption(option);
      },
      // 记录时间选择 --顶部搜索框
      handleRecordDateChange(e) {
        this.queryForm.start = e[0]
        this.queryForm.end = e[1]
      },
    }
  }
</script>


<style scoped>
  .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  #drivers {
    width: 45%;
    height: 500px;
    margin: 20px;
  }

  #members {
    width: 45%;
    height: 500px;
    margin: 20px;
  }

  #targets {
    width: 45%;
    height: 500px;
    margin: 20px;
  }
</style>